
.box h1 {
  color:#eabe39;
  font-size: 40px;
  font-family: 'Montserrat';
}

.special-container h1 {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  background-color:#eabe39;
  color: white;
  font-size: 40px;
  font-family: 'Montserrat';
  width: 100%;
}

.special-container h4 {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  background-color:#522874;
  font-size: 25px;
  font-family: 'Montserrat';
}

.link-container h4{
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 25px;
  font-family: 'Montserrat';
  
}

.link-container {
  padding-bottom: 10px;
}

.link-container a {
  padding: 20px;
  color: #522874;
  border: .5px solid white;
  border-radius: 10px;
  background-color: #eabe39;
}

.link-container a:hover {
  color: white;
}

.email-container h4 {
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  background-color:#522874;
  font-size: 25px;
  font-family: 'Montserrat';
}

.email-container {
  background-color:#522874;
  padding-top: 30px;
  padding-bottom: 30px;
}

.email-container a {
  color:#eabe39;
  word-wrap: break-word;
}

.email-container a:hover {
  color: black;
}

.special-container p {
  padding-top: 20px;
  padding-bottom: 20px;
  color: black;
  font-size: 20px;
  font-family: 'Montserrat';
}

.topnav {
  z-index: 9999999999;
  background-color: #522874;
  overflow: hidden;
  font-family: 'Montserrat';
  position: sticky;
  top: 0;
  width: 100%;
}

.topnav a {
  display: block;
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px; 
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #eabe39;
  color: white;
}

.topnav-right {
  float: right;
}

.box h2 {
  color: black;
  font-size: 30px;
  font-family: 'Montserrat';
}

.box h3 {
  color: black;
  font-size: 20px;
  font-family: 'Montserrat';
}

.box p {
  color: black;
  font-size: 20px;
  font-family: 'Montserrat';
}

.spacing-container {
  margin-bottom: 25px;
}

.container p {
  color: black;
  font-size: 20px;
  font-family: 'Montserrat';
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media screen and (max-width: 600px) {
  .container p {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.another-container p {
  color: black;
  font-size: 20px;
  font-family: 'Montserrat';
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .another-container p {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.another-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.no-space-container p {
  color: black;
  font-size: 20px;
  font-family: 'Montserrat';
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 50px;
}

@media screen and (max-width: 600px) {
  .no-space-container p {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.special-qualification-container {
  color:#522874;
  font-size: 25px;
  font-family: 'Montserrat';
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.no-top-special-qualification-container {
  color:#522874;
  font-size: 25px;
  font-family: 'Montserrat';
}

.qualification-container p {
  padding-bottom: 10px;
}

.qualification-container {
  color: black;
  font-size: 20px;
  font-family: 'Montserrat';
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
}

.qualification-container p:hover {
  color:#eabe39;
}

.sub-container p {
  color: black;
  font-size: 20px;
  font-family: 'Montserrat';
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
}

.other-sub-container p {
  color: black;
  font-size: 20px;
  font-family: 'Montserrat';
  padding-left: 100px;
  padding-right: 100px;
}

.recruitment-schedule-container h4 {
  color: #522874;
  font-size: 25px;
  font-family: 'Montserrat';
  margin-top: 70px;
  margin-bottom: 70px;
}

.container h4 {
  color: #522874;
  font-size: 25px;
  font-family: 'Montserrat';
}

.box h4 {
  color: #522874;
  font-size: 25px;
  font-family: 'Montserrat';
}

.container iframe {
  display: block;
  position: relative;
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media screen and (max-width: 1000px) {
  .container iframe {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.logo-image {
  width: 30px;
  height: 31.5px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -6px;
}

.rectangular-column-container-1 {
  display: table;
  width: 100%;
}

.rectangular-column-1 {
  display: table-cell;
  padding: 30px;
  text-align: center;
  color: black;
  font-size: 40px;
  font-family: 'Montserrat';
  border-right: .5px solid white;
}

.rectangular-column-1 h1 {
  color: black;
  font-size: 40px;
  font-family: 'Montserrat';
  padding-bottom: 10px;
}

.rectangular-column-1 h4 {
  color: white;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  font-size: 35px;
  font-family: 'Montserrat';
  border: .5px solid #522874;
  border-radius: 20px;
  background-color: #eabe39;
}

.rectangular-column-1 a {
  color: #522874;
}

.rectangular-column-1 a:hover {
  color: white;
}

@media screen and (max-width: 600px) {
  .rectangular-column-1 {
    display: block;
    width: 100%;
    border-bottom: .5px solid white;
  }
}

.rectangular-column-container {
  display: table;
  width: 100%;
}

.rectangular-column {
  display: table-cell;
  padding: 30px;
  text-align: center;
  color: black;
  font-size: 20px;
  font-family: 'Montserrat';
  background-color: #eabe39;
}

.rectangular-column h1 {
  color: black;
  font-size: 25px;
  font-family: 'Montserrat';
  padding-bottom: 10px;
}

.rectangular-column h4 {
  color: white;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 25px;
  font-family: 'Montserrat';
}

.rectangular-column a {
  color: #522874;
}

.rectangular-column a:hover {
  color: white;
}

@media screen and (max-width: 600px) {
  .rectangular-column {
    display: block;
    width: 100%;
  }
}

.topnav input[type=password] {
  margin-left: 12px;
  padding: 9px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
  width: 150px;
  font-family: 'Montserrat';
}

.topnav .login-container button {
  padding: 9px;
  margin-top: 8px;
  margin-right: 16px;
  background: #eabe39;
  font-size: 17px;
  border: none;
  cursor: pointer;
  font-family: 'Montserrat';
  color: #f2f2f2;
}

.topnav .login-container button:hover {
  background-color: #ddd; 
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 4px;
  background-color: black;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.time-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

.time-container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color: white;
  border: 4px solid #eabe39;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.left {
  left: 0;
}

.right {
  left: 50%;
}

.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid #522874;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #522874;
}

.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #522874;
  border-width: 10px 10px 10px 0;
  border-color: transparent #522874 transparent transparent;
}

.right::after {
  left: -13px;
}

.content {
  padding: 20px 30px;
  background-color: #522874;
  position: relative;
  border-radius: 6px;
  color: white;
  font-family: 'Montserrat';
  text-align: center;
}

.timeline a {
  color:#eabe39;
}

.timeline a:hover {
  color: black;
}

@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
    .timeline::after {
      left: 31px;
    }
  
  /* Full-width containers */
    .time-container {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
    }
  
  /* Make sure that all arrows are pointing leftwards */
    .time-container::before {
      left: 60px;
      border: medium solid #522874;
      border-width: 10px 10px 10px 0;
      border-color: transparent #522874 transparent transparent;
    }
  
  /* Make sure all circles are at the same spot */
    .left::after, .right::after {
      left: 18px;
    }
  
  /* Make all right containers behave like the left ones */
    .right {
      left: 0%;
    }
  }

  .top-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/public/pictures/unc_top_image.jpg");
    height: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 300px;
  }

  .top-image-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 40px;
    font-family: 'Montserrat';
  }

  .tripod-row {
    margin-bottom: -6px;
  }

  .tripod-row::after {
    content: "";
    clear: both;
    display: table;
  }

  .tripod-col {
    float: left;
    width: 33.33%;
    padding: 0;
  }

  .mission-row1 {
    margin-bottom: -6px;
  }

  .mission-row1::after {
    content: "";
    clear: both;
    display: table;
  }

  .mission-col1 {
    float: left;
    width: 33.33%;
    padding: 20px;
  }

  .mission-row2 {
    margin-bottom: -6px;
    background-color: #522874;
    padding-left: 15px;
    padding-right: 15px;
  }

  .mission-row2::after {
    content: "";
    clear: both;
    display: table;
  }

  .mission-col2 {
    float: left;
    width: 50%;
    padding: 20px;
  }

  .mission-row {
    margin-bottom: -6px;
  }

  .mission-row::after {
    content: "";
    clear: both;
    display: table;
  }

  .mission-col {
    float: left;
    width: 33.33%;
    padding: 0;
    background-color:#522874;
  }

  .footer-container {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color:#522874;
    color: white;
    font-size: 15px;
    font-family: 'Montserrat';
    width: 100%;
    text-align: center;
  }

  .fa {
    padding: 20px;
    font-size: 30px;
    width: 100px;
    text-align: center;
    text-decoration: none;
  }

  .fa:hover {
    color:#eabe39
  }

  .fa-instagram {
    background:#522874;
    color: white;
  }

  .fa-arrow-up {
    color: white;
  }

  .container-attendance-iframe {
    margin-bottom: -6px;
  }

  .form-popup {
    display: none;
    position: fixed;
    top: 54px;
    right: 15px;
    border: 3px solid white;
    z-index: 9;
  }

  .form-popup label {
    font-size: 17px;
    font-family: 'Montserrat';
  }

  .form-popup h1 {
    text-align: center;
    font-size: 20px;
    font-family: 'Montserrat';
  }

  .form-container {
    max-width: 300px;
    padding: 10px;
    background-color:#eabe39;
  }

  .form-container input[type=text],
  .form-container input[type=password] {
    width: 100%;
    padding: 15px;
    font-size: 17px;
    font-family: 'Montserrat';
    margin: 5px 0 22px 0;
    border: none;
    background: white;
  }

  .form-container input[type=text]:focus,
  .form-container input[type=password]:focus {
    background-color: white;
    outline: none;
  } 

  .form-container .btn {
    background-color: #522874;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    opacity: 0.8;
    font-size: 13px;
    font-family: 'Montserrat';
  }

  .form-container .cancel {
    background-color: black;
  }

  .form-container .btn:hover {
    opacity: 1;
  }

.column img:hover {
  border: 3px solid #eabe39;
}

.row {
  background-color:#522874;
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}
  

  
   

  



  









